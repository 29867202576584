import React from 'react'

const Main = () => {
    return (
        <>
            <div className='content-left'>
                <div className='my-name'>Zac Harker</div>
                <div className='my-title'>Software Engineer</div>
            </div>
            <div className='content-right'>
                <div className='links'>
                    <ul>
                        <li>
                            <a href='https://www.linkedin.com/in/zac-harker/' title='View my Resume on LinkedIn' className='tooltip'>Resume available on LinkedIn</a>
                        </li>
                        <li>
                            ✉️ zac.harker+jobs@gmail.com
                        </li>
                    </ul>
                </div>
                <div className='about'>
                    <p>Hi there! I'm a software engineer that loves to write JavaScript code for all kinds of efforts with a huge focus on React and Nodejs.
                        I specialize in writing code for frontend projects and also work with BFF and API layers, including use of GraphQL.
                    </p>
                    <p>
                        Currently, you will find me working remotely on upcoming care apps at Kaiser Permanente in Pleasanton, California.
                        You might have seen some of my work before, as I helped build dashboards and content displays for queue kiosks, wait time displays, and other dashboards for clinicians.
                    </p>
                    <p>
                        Feel free to reach out by clicking on my LinkedIn profile and sending me InMail. You can also find additional contact details on there. Or, if you prefer email, you can find my address above.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Main;