import './App.css';
import Main from "./components/Main";

function App() {
  return (

    <div className="App">
      <div className="background">
      <header className="App-header">
          <div className='title'>Zac Harker</div>
          <div className='menu-spacer'/>
          <div className='toolbox'>2021</div>
      </header>
      <section className="App-main-container">
          <Main/>
      </section>
      <footer className="App-footer">
      </footer>
      </div>
    </div>
  );
}

export default App;
